<template>
    <div class="desktopLayoutClass">
        <!-- appVersionFlag Dialog Start -->
        <div v-show="appVersionFlag">
            <v-row justify="center">
                <v-dialog v-model="appVersionFlag" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal">
                            <p> {{ $t("Warning_Message.AppUpdatePromptVideoCall") }} </p>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="closeAppVersionFlag">
                                {{ $t("Warning_Message.Update") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- appVersionFlag Dialog End -->

        <v-card
            active-class="active"
            elevation="0"
            height="88"
            justify="center"
            :class="doctor.selectedClass"
            @click="alternate(doctor)"
            style="border-radius: 8px; border: 1px solid #E0E0E0;"
            >
            <div style="display:flex; flex-direction:row; justify-content:flex-start;">
                <div style="padding-top:10px; padding-left:15px;">
                    <div style="display:flex; flex-direction:column;" v-if="doctor.offer_length && doctor.offer_length > 0">
                        <img style="height:56px; width: 60px; border-radius:10px 10px 0 0;" v-bind:src="doctor.doctor_profile_picture" alt="Image"/>
                        <div class="offer_indication" style="border-radius:0 0 10px 10px;">
                            <img src="https://s3iconimages.mymedicine.com.mm/percentage_logo.svg" />
                            <span class="offer_text">{{doctor.offer_length}} {{$t("Customer.ViewDoctorsPage.Offer")}} </span>
                        </div>
                    </div>
                    <img v-else style="height:56px; width: 56px; border-radius:10px;" v-bind:src="doctor.doctor_profile_picture" alt="Image"/>
                </div>
                <div align='left' style="padding-top:10px; padding-left:15px; display:flex; flex-direction:column; justify-content:space-evenly;">
                    <div style="margin-top: 1%; font-size: 12px; color: #222B45; font-weight: 600; font-size: 14px; display:flex; flex-direction:row; justify-content:flex-start; text-align: left;">
                            {{ doctor.doctor_name }}
                    </div>
                    <div class="specialistName text-truncate" style="line-height: 20px;align-self: flex-start; text-align: left;" align="left">
                            {{ specName ? specName : doctor.specialization_name }}
                    </div>
                    <div style="display:flex; flex-direction:row; justify-content:space-between; padding-top: 0%">
                        <div style="
                                padding-right:10px;
                                padding-top: 3px;
                                font-size: 0.8em;
                                font-weight: 500;
                                white-space: nowrap;
                                overflow: hidden;
                                color: #219653;
                            ">
                            {{ doctor.fee }} {{ $t("Customer.BookAppointment.MMK") }}
                        </div>
                        <div v-if="walkin === 'Walkin'" style="padding-top:0%; position:absolute; right:15px;">
                            <v-btn @click="alternate_func(doctor)" style="padding:11px; height: 10px; background-color: #0054A6; border-radius: 3px; font-size: 10px; color:#FFFFFF; text-transform: none;">{{ $t("Customer.ViewDoctorsPage.View_Slot") }}</v-btn>
                        </div>
                        <div v-else style="padding-top:0%; position:absolute; right:15px;">
                            <v-btn @click="alternate(doctor)" style="padding:11px; height: 10px; background-color: #0054A6; border-radius: 3px; font-size: 10px; color:#FFFFFF; text-transform: none;">{{ $t("Customer.ViewDoctorsPage.View_Slot") }}</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </v-card> 
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "Doctors",
    props : ['doctor','specName', 'walkin', 'pageName'],
    data() {
        return {
            appVersionFlag: false,
            isViewedDoctor: false
        }
    },
    methods:{
        alternate_func(obj){
            let walkIn = "walkin"
            if (!this.isViewedDoctor) {
                this.viewedDoctorTableFunction(obj._id)
            }
            this.$router.push({
                name: 'newBookingPage',
                params: { docId: obj._id, walkIn: walkIn }
            });
        },
        alternate(obj){
            obj.selectedClass =  obj.selectedClass === 'defaultClass' ? 'activeClass' : 'defaultClass';
            let userAgent = navigator.userAgent;
            if (userAgent.includes("kbzpay")) {
                let slicedData = userAgent.slice(userAgent.indexOf("kbzpay")).split('/');
                if (slicedData[slicedData.length-1] < "5.2.3") {
                    this.appVersionFlag = true;
                }
            }
            if (!this.isViewedDoctor) {
                this.viewedDoctorTableFunction(obj._id)
            }
            if (!this.appVersionFlag) {
                var params_records = this.$route.query;
                if(this.$route.fullPath.includes('/customer/home') || params_records.type == 'ServiceDoctors' || this.pageName == 'CustomerHomeScreen_serviceDoctors' || this.pageName == 'viewDoctors_serviceDoctors') {
                    localStorage.setItem('bookingType', 'oldServiceAppointment');
                }          
                let walkIn = "Booking";
                this.$router.push({
                    name: 'newBookingPage',
                    params: { docId: obj._id, walkIn: walkIn}
                });
            }
        },
        redirectToHomePage(){
          this.$router.push({
            name: "CustomerHomeScreen",
          });
        },
        closeAppVersionFlag() {
            this.appVersionFlag = false;
            kbzpay.gotoFunction('native://kbz/customer/about');
        },
        viewedDoctorTableFunction(doctorId) {
            this.isViewedDoctor = true;
            let token = this.$cookies.get("customerToken") === null ? '': this.$cookies.get("customerToken");
            let viewedDoctorTableBody = {
                doctorId: doctorId,
                token: token,
                typeOfUser: 'CUSTOMER',
                pageName: this.$props.pageName
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+'/viewedDoctorTable', viewedDoctorTableBody)
            this.isViewedDoctor = false;
        }
    }
}
</script>

<style scoped>
.specialistName{     
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #828282;
}
.offer_indication {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
    gap: 4px;
    height: 15px;
    width: 60px;
    background: #EB5757;
    backdrop-filter: blur(1px);
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;
}
.offer_text {
    width: 32px;
    height: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
}

</style>
